<template>
<div>

    <svg xmlns="http://www.w3.org/2000/svg" class="blog fixed-center" viewBox="0 0 1091.24 309.09">
        <path class="cls-1" d="M377.89,546.42H500.16c51.42,0,86.08-36.56,86.08-80.75,0-25.51-14.47-45.89-36.19-58.27,15.62-13.33,25.52-31.42,25.52-51.8C575.57,335,568,317.32,555,304l34.66,34.66c12.57,12.57,21.9,30.47,21.9,54.85,0,19.42-8.19,36.56-21.52,49.7l12.19,12.19c11.42,11.43,20,28.38,20,50.09,0,44.18-36.18,78.84-89.89,78.84H415.79Zm56.76-216h53.7c16.38,0,28.57,11.43,28.57,28.19v2.66H466.83v25.52H434.65Zm0,104.36h58.27c20.19,0,33.9,12.19,33.9,30.47v.38h-60v30.1H434.65Z" transform="translate(-377.89 -279.8)" />
        <path class="cls-1" d="M648.89,546.42H824.48V489.67l37.9,37.9v56.75H686.79ZM709.07,279.8l39.8,37.9v172h-39.8Z" transform="translate(-377.89 -279.8)" />
        <path class="cls-1" d="M933.6,540.14l-25-25.14A139.34,139.34,0,0,0,1103.1,315.41l34.09,34.09c25.52,25.52,42.47,57.9,42.47,101.89,0,76.18-63.42,137.5-139.41,137.5C995.3,588.89,966.74,573.28,933.6,540.14Zm-7.43-126.65c0-45.32,34.28-80.75,78.09-80.75a77.2,77.2,0,0,1,69.89,44c-10.09-7.81-21.52-11.81-35.8-11.81-43.81,0-78.09,35.42-78.09,80.75,0,16.95,2.67,30.09,11.62,41.9C944.65,475,926.17,446.82,926.17,413.49Z" transform="translate(-377.89 -279.8)" />
        <path class="cls-1" d="M1262.69,547.38,1231.26,516c24.38,22.47,58.85,36.95,99.8,36.95,33.9,0,76.18-9.9,98.27-23.23V391.78l39.8,37.9V567.56c-22.47,12.19-65.7,21.33-100.17,21.33C1323.25,588.89,1288.59,573.28,1262.69,547.38Zm-12.76-133.13c0-48.94,37-81.89,91.79-81.89,23.62,0,53,6.1,72.75,18.29V290.08l37.9,37.9v60.56c-19.8-14.09-52.94-24-76.56-24-54.85,0-91.79,32.95-91.79,81.89,0,14.47,2.66,28,11,39.8C1266.69,473.29,1249.93,447.2,1249.93,414.25Zm111.41,64.95-36-36h45v36.38Z" transform="translate(-377.89 -279.8)" /></svg>

    <v-container class="blog_box" fluid>
        <h1 class="text-center mb-10 page_title">ブログ</h1>
<Content  ScroY="ScroY"/>
    </v-container>

</div>
</template>

<script>
import Content from "@/components/BlogContent.vue"
export default {

    props:{
        ScroY:Number
    },
    components:{
        Content
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/blog.scss";
</style>
